import React from 'react';

export default function DownloadButton({data_in, name="lottie_to_use", children}){
  const handleDownload = () => {
    const jsonString = JSON.stringify(data_in);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}.json`;
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
      <button onClick={handleDownload}>{children}</button>
  );
};