import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import DownloadButton from "./Download_Json";
import { buildQueries } from "@testing-library/dom";

function DynamicLottieList({ animationsListUrl }) {
    const [animations, setAnimations] = useState([]);
    const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || {})
    const [loading, setLoading] = useState(true);
    const [width, setWidth] = useState(JSON.parse(localStorage.getItem('item_width')) || 25);

    useEffect(() => {
        localStorage.setItem('item_width', JSON.stringify(width));
    }, [width])


    useEffect(() => {
        const fetchAnimationsList = async () => {
            try {
                const response = await fetch(animationsListUrl);
                if (response.ok) {
                    const resp_data = await response.json();
                    let new_data = {}
                    for (const [key, value] of Object.entries(resp_data)) {
                        if (data[key] !== undefined) {
                            new_data[key] = data[key]
                        } else {
                            new_data[key] = value;
                        }
                    }
                    setData(new_data);
                    setLoading(false);
                } else {
                    console.error("Error loading animations list");
                }
            } catch (error) {
                console.error("Error fetching the animations list", error);
            }
        };

        fetchAnimationsList();
    }, [animationsListUrl]);

    useEffect(() => {
        let anim_data = []
        for (const [key, value] of Object.entries(data)) {
            let item = { "id": key, ...value }
            anim_data.push(item);
        }
        setAnimations(anim_data);
        localStorage.setItem('data', JSON.stringify(data));
    }, [data])

    function on_item_click(e) {
        let prev_is_good = data[e]['is_good']
        setData((prev) => {
            prev[e]['is_good'] = !prev_is_good
            return { ...prev }
        })
    }

    return (
        <>
            <div className="l_header">

                <div className="slidecontainer">
                    <input type="range" min="1" max="100" onChange={(e) => setWidth(e.target.value)} value={width} className="slider"></input>
                </div>
                <DownloadButton data_in={data}>Експортувати</DownloadButton>
            </div>
            <div className="lottie_container">
                <div className="lottie_items">
                    {animations.map((animation) => (
                        <AnimationItem key={animation.id} item_id={animation.id} width={width} animationPath={animation.path} is_good={animation.is_good} on_click={on_item_click} />
                    ))}
                </div>
            </div>
        </>
    )
};


export function AnimationItem({ item_id, animationPath, width, is_good, on_click = () => { } }) {
    const [animationData, setAnimationData] = useState(null);
    const lottieRef = useRef();

    const loadAnimation = async (path) => {
        try {
            const response = await fetch(path);
            if (response.ok) {
                return response.json();
            } else {
                console.error("Failed to load animation from path:", path);
            }
        } catch (error) {
            console.error("Error loading the animation JSON:", error);
        }
    };

    const loadAnimationData = async () => {
        const data = await loadAnimation(animationPath);
        setAnimationData(data);
    };

    useEffect(() => {
        setTimeout(loadAnimationData, parseInt(item_id) * 300);
    }, [animationPath]);

    function play() {
        if (lottieRef.current) {
            lottieRef.current.play()
        }

    }

    function stop() {
        if (lottieRef.current) {
            lottieRef.current.pause()
        }
    }

    return (
        <div className={`lottie_item_container ${is_good ? "good" : ""}`} style={{ width: `${width}%` }}>
            <div className={`lottie_header ${is_good ? "good" : ""}`}>
                {is_good ?
                    <p>Катить</p>
                    : <p>Не катить</p>
                }
                {animationData ?
                    <DownloadButton data_in={animationData} name={item_id}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                    </DownloadButton> : null
                }
            </div>
            <button onClick={() => on_click(item_id)} className={`lottie_animation ${!animationData ? "no_data" : ""}`}>

                {animationData ?
                    <Lottie lottieRef={lottieRef} animationData={animationData} loop={true} autoplay={true} />
                    :
                    <p>Завантажують анімацію...</p>}
            </button>
        </div>
    )
};

export default DynamicLottieList;
