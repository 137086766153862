import './App.css';
import DynamicLottieList, {AnimationItem} from './Lottie_Item';
import Lottie from "lottie-react";


function App() {
  return (
    <div className="main">
      <DynamicLottieList animationsListUrl={'/lottie_files.json'} />
    </div>
  );
}

export default App;
